import React from "react"
import theme from "theme"
import {
  Theme,
  Text,
  Box,
  Section,
  Strong,
  Image,
  Link,
} from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Головна | Legal Pro Firm</title>
        <meta
          name={"description"}
          content={"Провідник через юридичні джунглі"}
        />
        <meta property={"og:title"} content={"Головна | Legal Pro Firm"} />
        <meta
          property={"og:description"}
          content={"Провідник через юридичні джунглі"}
        />
        <meta
          property={"og:image"}
          content={"https://ua.rivoxenpro.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://ua.rivoxenpro.com/img/icon.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://ua.rivoxenpro.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://ua.rivoxenpro.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://ua.rivoxenpro.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://ua.rivoxenpro.com/img/icon.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://ua.rivoxenpro.com/img/icon.png"}
        />
        <meta
          name={"msapplication-TileColor"}
          content={"https://ua.rivoxenpro.com/img/icon.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://ua.rivoxenpro.com/img/1.jpg) center/cover"
        padding="60px 0"
        sm-padding="40px 0"
        color="--light"
        font="--base"
        min-height="700px"
      >
        <Box margin="-16px -16px -16px -16px" display="flex">
          <Box
            display="flex"
            padding="16px 16px 16px 16px"
            width="75%"
            md-width="100%"
          >
            <Box display="flex" flex-direction="column">
              <Text
                color="--lightD2"
                letter-spacing="1px"
                text-transform="uppercase"
                margin="0"
              >
                Ваша подорож до правосуддя починається тут
              </Text>
              <Text
                as="h1"
                font="--headline1"
                md-font="--headline2"
                margin="100px 0"
              >
                Legal Pro Firm
              </Text>
              <Text color="--lightD2" letter-spacing="1px" margin="0">
                Ласкаво просимо! У Юридичній фірмі "Legal Pro" ми прагнемо
                запропонувати вам комплексні юридичні рішення, що відповідають
                вашим потребам. Наша команда розуміє тонкощі правової системи і
                готова провести вас через кожен крок, забезпечуючи ясність і
                спокій. Незалежно від того, чи стикаєтесь ви з особистими
                юридичними проблемами, чи шукаєте пораду для свого бізнесу, ми
                допоможемо вам досягти успішного результату.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="60px 0 90px 0"
        sm-padding="40px 0"
        sm-min-height="auto"
        display="flex"
        quarkly-title="Advantages/Features-11"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          grid-gap="5%"
          sm-min-width="280px"
        />
        <Box
          display="flex"
          width="100%"
          flex-direction="column"
          justify-content="center"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="flex-start"
          lg-margin="0px 0px 60px 0px"
          sm-margin="0px 0px 40px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-flex-direction="row"
          lg-flex-wrap="wrap"
          sm-width="100%"
          md-margin="0px 0px 30px 0px"
          margin="0px 0px 64px 0px"
          lg-justify-content="flex-start"
        >
          <Text
            as="h1"
            margin="0px"
            font="--headline2"
            color="--dark"
            width="100%"
            md-width="100%"
            md-margin="0px 0px 32px 0px"
            md-padding="0px 0px 0px 0px"
            sm-font="normal 600 40px/1.2 --fontFamily-sans"
            text-align="left"
          >
            Чому обирають нас?
          </Text>
          <Text
            as="p"
            margin="20px 0 0 0"
            font="--lead"
            font-size="20px"
            font-weight="300"
            color="--dark"
            width="60%"
            md-width="100%"
            md-margin="0px 0px 0px 0px"
            md-padding="0px 0px 0px 0px"
            sm-font="--base"
            text-align="left"
            lg-width="100%"
            lg-max-width="720px"
          >
            У Legal Pro ми пишаємося нашим особливим підходом до надання
            юридичних послуг. Ми прагнемо не просто вирішити ваші юридичні
            питання, але й зробити це з непохитною підтримкою, прозорістю та
            інноваціями. Ось чому вам слід звернутися до нас:
          </Text>
        </Box>
        <Box
          width="100%"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="32px"
          lg-grid-template-columns="1fr"
          lg-grid-gap="48px"
        >
          <Box
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            justify-content="space-between"
          >
            <Box
              align-items="flex-start"
              display="flex"
              flex-direction="column"
              lg-max-width="720px"
            >
              <Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
                <Strong>Персоналізована увага: </Strong>
              </Text>
              <Text margin="0px 0px 24px 0px" font="--base" color="--darkL2">
                Кожна справа є унікальною, і ми ставимося до неї як до такої,
                пропонуючи індивідуальні поради та стратегії.
              </Text>
            </Box>
          </Box>
          <Box
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            justify-content="space-between"
          >
            <Box
              align-items="flex-start"
              display="flex"
              flex-direction="column"
              lg-max-width="720px"
            >
              <Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
                <Strong>Експертиза в усіх сферах: </Strong>
              </Text>
              <Text margin="0px 0px 24px 0px" font="--base" color="--darkL2">
                Наша команда має широкий спектр спеціальностей, що гарантує, що
                ви отримаєте компетентну консультацію, незалежно від складності.
              </Text>
            </Box>
          </Box>
          <Box
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            justify-content="space-between"
          >
            <Box
              align-items="flex-start"
              display="flex"
              flex-direction="column"
              lg-max-width="720px"
            >
              <Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
                <Strong>Прозора комунікація: </Strong>
              </Text>
              <Text margin="0px 0px 24px 0px" font="--base" color="--darkL2">
                Ми прагнемо надавати вам повну інформацію, роз'яснюючи юридичні
                терміни, щоб ви могли приймати рішення з упевненістю.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section padding="80px 0 80px 0">
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          align-items="center"
          flex-direction="column"
          justify-content="center"
          margin="0px 0px 64px 0px"
          width="100%"
          sm-margin="0px 0px 30px 0px"
          padding="0px 200px 0px 200px"
          lg-padding="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 16px 0px"
            font="--headline2"
            text-align="center"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          >
            Наші комплексні юридичні пропозиції
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            text-align="center"
            font="--lead"
          >
            У фірмі Legal Pathways ми розуміємо, що кожен клієнт має унікальний
            набір проблем і потреб. Наші послуги спрямовані на надання всебічної
            підтримки, супроводжуючи вас у кожному аспекті юридичного процесу зі
            знанням справи та емпатією. Нижче ви знайдете огляд спеціалізованих
            послуг, які ми надаємо, кожна з яких розроблена з урахуванням
            специфіки правового середовища.
          </Text>
        </Box>
        <Box
          display="grid"
          flex-wrap="wrap"
          width="100%"
          align-items="center"
          justify-content="center"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="30px"
          md-grid-template-columns="1fr"
        >
          <Box
            width="100%"
            display="flex"
            padding="7px 7px 40px 7px"
            border-radius="24px"
            align-items="center"
            flex-direction="column"
            border-width="1px"
            border-style="solid"
            border-color="--color-lightD2"
            justify-content="center"
          >
            <Image
              src="https://ua.rivoxenpro.com/img/2.jpg"
              border-radius="16px"
              margin="0px 0px 32px 0px"
              max-width="100%"
              md-width="100%"
              width="100%"
              height="278px"
              object-fit="cover"
            />
            <Box
              display="flex"
              flex-direction="column"
              padding="0px 30px 0px 30px"
              align-items="flex-start"
              justify-content="flex-start"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3">
                Сімейне право
              </Text>
              <Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
                Розлучення та роздільне проживання: Як розірвати стосунки зі
                співчуттям і ясністю, не порушуючи законів.
                <br />
                Опіка над дітьми: Забезпечення найкращих інтересів ваших дітей
                на першому плані.
                <br />
                Поділ майна: Справедливий і рівноправний розподіл майна.
                <br />
                Усиновлення: Юридичний супровід на радісному шляху розширення
                вашої родини.
                <br />
                Внутрішнє усиновлення: Орієнтуємося в державних нормах, щоб
                прийняти нового члена сім'ї.
                <br />
                Міжнародне усиновлення: Подолання транскордонних складнощів для
                об'єднання сімей.
              </Text>
            </Box>
          </Box>
          <Box
            width="100%"
            display="flex"
            padding="7px 7px 40px 7px"
            border-radius="24px"
            align-items="center"
            flex-direction="column"
            border-width="1px"
            border-style="solid"
            border-color="--color-lightD2"
            justify-content="center"
            position="relative"
          >
            <Image
              src="https://ua.rivoxenpro.com/img/3.jpg"
              border-radius="16px"
              margin="0px 0px 32px 0px"
              max-width="100%"
              md-width="100%"
              max-height="278px"
              object-fit="cover"
              width="100%"
            />
            <Box
              display="flex"
              flex-direction="column"
              padding="0px 30px 0px 30px"
              align-items="flex-start"
              justify-content="flex-start"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3">
                Кримінальний захист
              </Text>
              <Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
                Порушення правил дорожнього руху та водіння в нетверезому стані:
                Захист ваших прав та ліцензії за допомогою енергійного захисту.
                <br />
                Відновлення прав: Допомагаємо відновити водійські права.
                <br />
                Укладення угоди про визнання вини: Обговорення умов для
                мінімізації покарання.
                <br />
                Службові злочини: Експертний захист у справах про шахрайство,
                розкрадання та інші фінансові злочини.
                <br />
                Захист від шахрайства: Стратегічний захист від складних
                звинувачень.
                <br />
                Комплаєнс та запобігання: Консультуємо бізнес, як уникнути
                юридичних пасток.
              </Text>
            </Box>
          </Box>
          <Box
            width="100%"
            display="flex"
            padding="7px 7px 40px 7px"
            border-radius="24px"
            align-items="center"
            flex-direction="column"
            border-width="1px"
            border-style="solid"
            border-color="--color-lightD2"
            justify-content="center"
          >
            <Image
              src="https://ua.rivoxenpro.com/img/4.jpg"
              border-radius="16px"
              margin="0px 0px 32px 0px"
              max-width="100%"
              md-width="100%"
              max-height="278px"
              object-fit="cover"
              width="100%"
            />
            <Box
              display="flex"
              flex-direction="column"
              padding="0px 30px 0px 30px"
              align-items="flex-start"
              justify-content="flex-start"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3">
                Корпоративне право
              </Text>
              <Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
                Створення та структурування бізнесу: Побудова міцного фундаменту
                для вашого бізнесу.
                <br />
                Товариства з обмеженою відповідальністю, партнерства та
                корпорації: Вибір правильної структури для вашого бізнесу.
                <br />
                Комплаєнс та управління: Забезпечення того, щоб ваш бізнес
                працював у правовому полі.
                <br />
                Захист інтелектуальної власності: Захист ваших інновацій та
                творінь.
                <br />
                Торгові марки та патенти: Захист вашого бренду та винаходів.
                <br />
                Авторські права: Захист ваших оригінальних творів від
                несанкціонованого використання.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-11"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          lg-width="100%"
          lg-align-items="center"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          flex-direction="column"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
        >
          <Text
            sm-font="--headline3"
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            md-text-align="left"
          >
            Ваша подорож до правосуддя починається тут
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
            Дозвольте Юридичній фірмі Legal Pro стати вашим провідником і
            союзником у юридичному світі. Зв'яжіться з нами сьогодні, щоб стати
            на шлях до вирішення проблеми та душевного спокою.
          </Text>
          <Link href="/contacts" color="#000000" margin="30px 0px 0px 0px">
            Звʼязатися
          </Link>
        </Box>
        <Box
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            width="100%"
            height="auto"
            padding="0px 0px 70% 0px"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
          >
            <Image
              position="absolute"
              width="100%"
              top="auto"
              left={0}
              src="https://ua.rivoxenpro.com/img/5.jpg"
              object-fit="cover"
              display="block"
              right={0}
              bottom="0px"
              min-height="100%"
            />
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
